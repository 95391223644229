import React from "react";
import { FaCode, FaMobileAlt, FaRobot, FaPython, FaJava, FaUsers, FaBullhorn, FaClipboardList, FaProjectDiagram, FaCertificate, FaRegClock, FaHandshake, FaNetworkWired, FaChalkboardTeacher } from "react-icons/fa"; // Import icons
import sampleCertificate from "../assets/sample.png"; // Replace with your image path
import { Helmet } from 'react-helmet';

const CareerPage = () => {
    // Define the form URL in one place
    const formUrl = "https://forms.gle/yWuKS4tj1Qd18ffW9";
  return (
    <div>
          {/* SEO Meta Tags */}
          <Helmet>
        <title>Internship Opportunities at VMAD Technologies</title>
        <meta
          name="description"
          content="Jumpstart your career with VMAD Technologies! Explore internship opportunities and gain valuable experience in a flexible work environment."
        />
        <meta
          name="keywords"
          content="internship, VMAD Technologies, web development, app development, machine learning, marketing, career opportunities"
        />
      </Helmet>

      {/* Section 1: Internship Overview (Hero Section) */}
      <section className="bg-gradient-to-r from-purple-200 via-blue-200 to-blue-100 text-center mt-2 py-20">
        <h1 className="text-4xl font-bold text-gray-800">
          Jumpstart Your Career with VMAD Technologies!
        </h1>
        <p className="text-xl text-gray-600 mt-4">
          Join our team and work on real-world projects while gaining valuable industry experience.
        </p>
        <p className="text-lg text-gray-500 mt-2">Get a chance for paid and full-time employment</p>
        <button
          onClick={() => window.location.href = formUrl}
          className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-500 transition duration-300 ease-in-out">
          Apply Now
        </button>
      </section>

      {/* Section 2: Why Choose VMAD for Internships */}
      <section className="bg-purple-50 py-12 px-4">
        <h2 className="text-3xl font-semibold text-center mb-6">
          Why VMAD is the Best Choice for Your Internship
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {[
            { title: "Industry-Relevant Projects", content: "Work on cutting-edge technology and contribute to impactful projects like KhetiBuddy and FaceGuard.", icon: <FaProjectDiagram className="text-blue-600 text-2xl" /> },
            { title: "Real-World Experience", content: "Get hands-on experience, mentorship from professionals, and work in a collaborative environment.", icon: <FaHandshake className="text-blue-600 text-2xl" /> },
            { title: "Certification & Credibility", content: "Receive an industry-recognized certificate upon completion, signed by our leadership.", icon: <FaCertificate className="text-blue-600 text-2xl" /> },
            { title: "Paid Opportunities", content: "HR internships are paid on a performance basis, and all internships give you a chance to transition into full-time employment.", icon: <FaBullhorn className="text-blue-600 text-2xl" /> },
            { title: "Flexible Work Environment", content: "Enjoy flexible working hours and remote work options.", icon: <FaRegClock className="text-blue-600 text-2xl" /> },
            { title: "Skill Development", content: "Enhance your skills through training sessions and hands-on projects with our experts.", icon: <FaChalkboardTeacher className="text-blue-600 text-2xl" /> },
          ].map((item, index) => (
            <div key={index} className="bg-gradient-to-r from-gray-200 to-gray-300 p-6 rounded-lg shadow-md transition-transform transform hover:scale-105">
              <div className="flex items-center">
                {item.icon}
                <h3 className="text-xl font-bold ml-2">{item.title}</h3>
              </div>
              <p>{item.content}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Section 3: Internship Domains */}
      <section className="bg-white py-12 px-4">
        <h2 className="text-3xl font-semibold text-center mb-6">Explore Our Internship Opportunities</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 text-center">
          {[
            { icon: <FaCode className="text-4xl text-blue-600" />, title: "Web Development", content: "Build responsive and dynamic websites using modern technologies.", link: formUrl },
            { icon: <FaMobileAlt className="text-4xl text-blue-600" />, title: "App Development", content: "Create user-friendly mobile applications for Android and iOS.", link: formUrl },
            { icon: <FaRobot className="text-4xl text-blue-600" />, title: "Machine Learning", content: "Work on AI-driven projects and develop predictive models.", link: formUrl },
            { icon: <FaPython className="text-4xl text-blue-600" />, title: "Python Development", content: "Utilize Python for web development and data analysis.", link: formUrl },
            { icon: <FaJava className="text-4xl text-blue-600" />, title: "Java Development", content: "Develop robust applications using Java frameworks.", link: formUrl },
            { icon: <FaUsers className="text-4xl text-blue-600" />, title: "HR & Talent Management (Performance-Based Paid)", content: "Assist in managing recruitment and employee relations.", link: formUrl },
            { icon: <FaBullhorn className="text-4xl text-blue-600" />, title: "Marketing ", content: "Engage in marketing strategies and campaigns to enhance brand visibility.", link: formUrl },
            { icon: <FaClipboardList className="text-4xl text-blue-600" />, title: "Social Media Management", content: "Manage and grow our social media presence effectively.", link: formUrl },
          ].map((domain, index) => (
            <div key={index} className="bg-gradient-to-r from-purple-50 via-blue-50 to-white p-6 rounded-lg shadow-md transition-transform transform hover:scale-105">
              {domain.icon}
              <p className="font-bold mt-4">{domain.title}</p>
              <p className="mt-2 text-gray-600">{domain.content}</p>
              <a href={domain.link} className="text-blue-500 underline">Apply Now</a>
            </div>
          ))}
        </div>
      </section>

      {/* Section 4: Benefits and Perks */}
      <section className="bg-gradient-to-r from-blue-100 to-blue-300 py-12 px-4">
        <h2 className="text-3xl font-semibold text-center mb-6">Benefits and Perks</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {[
            { title: "Growth Opportunities", content: "Interns are given a chance for full-time, paid roles upon completion.", icon: <FaProjectDiagram className="text-blue-600 text-2xl" /> },
            { title: "Remote-Friendly", content: "Work remotely with flexible timings.", icon: <FaRegClock className="text-blue-600 text-2xl" /> },
            { title: "Networking", content: "Collaborate with professionals from various fields and expand your network.", icon: <FaNetworkWired className="text-blue-600 text-2xl" /> },
            { title: "Mentorship", content: "Receive one-on-one guidance from our industry experts.", icon: <FaChalkboardTeacher className="text-blue-600 text-2xl" /> },
            { title: "Performance-Based Pay for HR Internships", content: "Earn while you learn, based on performance in the HR domain.", icon: <FaBullhorn className="text-blue-600 text-2xl" /> },
            { title: "Certification", content: "Receive a professional certificate upon successful completion of your internship.", icon: <FaCertificate className="text-blue-600 text-2xl" /> },
          ].map((perk, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md transition-transform transform hover:scale-105">
              <div className="flex items-center">
                {perk.icon}
                <h3 className="text-xl font-bold ml-2">{perk.title}</h3>
              </div>
              <p>{perk.content}</p>
            </div>
          ))}
        </div>
      </section>

     {/* Section 5: Steps to Apply for the Internship */}
     <section className="bg-white py-12 px-4">
        <h2 className="text-3xl font-semibold text-center mb-6">How to Apply</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          {[
            { title: "Step 1: Submit Your Application", content: "Fill out the application form for your desired internship domain." },
            { title: "Step 2: Review Process", content: "Our team will review your application and get back to you if you qualify." },
            { title: "Step 3: Receive Your Offer Letter", content: "If eligible, you'll receive an offer letter outlining your internship projects." },
            { title: "Step 4: Start Your Internship", content: "Begin your internship and access our support for any questions." },
            { title: "Step 5: Task Submission", content: "Submit your projects for review and receive feedback." },
            { title: "Step 6: Get Certified", content: "Upon satisfactory completion, receive your certificate and have a chance for full-time employment." },
          ].map((step, index) => (
            <div key={index} className="bg-gradient-to-r from-purple-50 to-blue-50 p-6 rounded-lg shadow-md transition-transform transform hover:scale-105">
              <h3 className="text-xl font-bold">{step.title}</h3>
              <p>{step.content}</p>
            </div>
          ))}
        </div>
      </section>

{/* Section 6: Sample Certificate */}
<section className="bg-purple-50 py-8 px-4">
  <h2 className="text-3xl font-semibold text-center mb-2">
    Sample Certificate
  </h2>
  <p className="text-center text-gray-600 mt-2">
    This certificate will be awarded upon successful completion of the internship.
  </p>
  <div className="flex py-0 justify-center">
    <img
      src={sampleCertificate}
      alt="Sample Certificate"
      className="transform h-auto w-auto scale-75 rounded-lg shadow-lg"
    />
  </div>
  
</section>





       {/* Section 7: Conclusion */}
       <section className="bg-gradient-to-r from-purple-100 to-blue-100 text-center py-12">
        <h2 className="text-3xl font-semibold mb-6">Ready to Join Us?</h2>
        <p className="text-lg text-gray-600 mb-4">
          Don’t miss this opportunity to grow your career with us!
        </p>
        <button  onClick={() => window.location.href = formUrl} className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-500">
          Apply Now
        </button>
      </section>
    </div>
  );
};

export default CareerPage;
