import React, { useState } from 'react';
import { FaBars, FaTimes, FaServicestack, FaUser, FaEnvelope, FaBriefcase } from 'react-icons/fa';
import vmad from '../assets/vmad.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-gray-200 shadow-md rounded-b-lg fixed top-0 left-0 right-0 z-50 transition-shadow duration-300 transform hover:scale-100" aria-label="Main Navigation">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center h-18">
                    <div className="flex-shrink-0">
                        <a href="/" aria-label="VMAD Technologies Home" className="text-2xl font-bold text-purple-700 hover:text-purple-600 transition-colors duration-200">
                            <img src={vmad} alt="VMAD Technologies Logo" className="h-20 w-auto transform scale-150 object-contain" />
                        </a>
                    </div>
                    <div className="hidden md:flex space-x-6">
                        <a href="/our-services" className="flex items-center text-gray-700 hover:text-purple-500 transition-colors duration-200" aria-label="Our Services">
                            <FaServicestack className="mr-1" /> Our Services
                        </a>
                        <a href="/about-us" className="flex items-center text-gray-700 hover:text-purple-500 transition-colors duration-200" aria-label="About Us">
                            <FaUser className="mr-1" /> About Us
                        </a>
                        <a href="/contact-us" className="flex items-center text-gray-700 hover:text-purple-500 transition-colors duration-200" aria-label="Contact Us">
                            <FaEnvelope className="mr-1" /> Contact Us
                        </a>
                        <a href="/careers" className="flex items-center text-gray-700 hover:text-purple-500 transition-colors duration-200" aria-label="Careers">
                            <FaBriefcase className="mr-1" /> Career
                        </a>
                    </div>
                    <div className="flex md:hidden">
                        <button onClick={toggleMenu} className="text-gray-700 hover:text-purple-500 transition-colors duration-200" aria-label={isOpen ? "Close Menu" : "Open Menu"}>
                            {isOpen ? <FaTimes className="h-6 w-6" /> : <FaBars className="h-6 w-6" />}
                        </button>
                    </div>
                </div>
            </div>
            <div className={`md:hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-40' : 'max-h-0 overflow-hidden'}`}>
                <div className="px-4 pt-2 pb-3 space-y-1 bg-[#f9f9f9] border-t border-gray-200 shadow-md">
                    <a href="/our-services" className="flex items-center text-gray-700 hover:text-purple-600 transition-colors duration-200" aria-label="Our Services">
                        <FaServicestack className="mr-1" /> Our Services
                    </a>
                    <a href="/about-us" className="flex items-center text-gray-700 hover:text-purple-600 transition-colors duration-200" aria-label="About Us">
                        <FaUser className="mr-1" /> About Us
                    </a>
                    <a href="/contact-us" className="flex items-center text-gray-700 hover:text-purple-600 transition-colors duration-200" aria-label="Contact Us">
                        <FaEnvelope className="mr-1" /> Contact Us
                    </a>
                    <a href="/careers" className="flex items-center text-gray-700 hover:text-purple-600 transition-colors duration-200" aria-label="Careers">
                        <FaBriefcase className="mr-1" /> Career
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
