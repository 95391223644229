import React from 'react';
import msme from "../assets/msme.png";
import iso from "../assets/iso.png";
import startupIndia from "../assets/digial.png"; // Ensure to use the correct logo

const AffiliationsSection = () => {
  return (
    <section className="bg-gradient-to-b from-purple-50 to-blue-50 shadow-lg rounded-lg p-8 text-center">
      <header>
        <h3 className="text-4xl font-bold text-purple-600 mb-4">Our Affiliations & Approvals</h3>
        <p className="text-xl text-gray-600 mb-8">
          We take pride in our partnerships and certifications that affirm our commitment to excellence and quality.
        </p>
      </header>
      <div className="flex justify-center items-center gap-10">
        <figure className="flex flex-col items-center transition-transform transform hover:scale-105" role="img" aria-labelledby="msme-label">
          <img src={msme} alt="Ministry of Micro, Small and Medium Enterprises Logo" className="w-24 h-24 object-contain mb-2" />
          <figcaption>
            <p id="msme-label" className="text-gray-700">Ministry of Micro, Small and Medium Enterprises</p>
            <p className="text-gray-500 text-sm">Supporting small businesses across India.</p>
          </figcaption>
        </figure>
        {/* <figure className="flex flex-col items-center transition-transform transform hover:scale-105" role="img" aria-labelledby="iso-label">
          <img src={iso} alt="ISO Certification Logo" className="w-24 h-24 object-contain mb-2" />
          <figcaption>
            <p id="iso-label" className="text-gray-700">ISO Certification</p>
            <p className="text-gray-500 text-sm">Ensuring international quality standards.</p>
          </figcaption>
        </figure> */}
        <figure className="flex flex-col items-center transition-transform transform hover:scale-105" role="img" aria-labelledby="startup-india-label">
          <img src={startupIndia} alt="Startup India Logo" className="w-24 h-24 scale-110 object-contain mb-2" />
          <figcaption>
            <p id="startup-india-label" className="text-gray-700">Digital India</p>
            <p className="text-gray-500 text-sm">Promoting innovation and entrepreneurship.</p>
          </figcaption>
        </figure>
      </div>
    </section>
  );
};

export default AffiliationsSection;
