import React from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from './components/heroSection';
import Services from './components/services';
import WhyChooseUs from './components/choose';
import Internship from './components/internship';
import AffiliationsSection from './components/affilations';

const Home = () => {
  return (
    <div className='bg-gradient-to-b from-blue-50 to-purple-50'>
      {/* SEO Optimization with React Helmet */}
      <Helmet>
        <title>VMAD Technologies | Leading IT Solutions & Internship Programs</title>
        <meta name="description" content="VMAD Technologies offers top-notch IT solutions, including software development, app development, and machine learning. Explore our certified internship programs." />
        <meta name="keywords" content="VMAD Technologies, IT solutions, custom software development, web development, app development, digital marketing, machine learning services, data analytics, software development company, technology solutions, mobile app development, innovative technology, business growth solutions, IT consulting, tech services, full-stack development, cloud solutions, UX/UI design, digital transformation, enterprise software, scalable solutions, e-commerce development, responsive web design" />
        <meta name="author" content="VMAD Technologies" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://vmadtechnologies.in" />
      </Helmet>

      {/* Content Sections */}
      <HeroSection />
      <Services />
      <WhyChooseUs />
      <section className="py-16 bg-gradient-to-b from-purple-50 to-purple-100">
  <div className="max-w-7xl mx-auto px-6 text-center">
    <h2 className="text-3xl md:text-4xl font-bold text-purple-800 mb-6">See Our Past Projects</h2>
    <p className="text-gray-600 text-lg md:text-xl mb-8">
      Explore the innovative solutions we've delivered to help businesses achieve their goals. 
      Take a look at our portfolio to see our expertise in action.
    </p>
    <a 
      href="https://vmadtechnologies-samples.vercel.app" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="inline-block bg-purple-600 text-white font-medium text-lg px-6 py-3 rounded-lg shadow-md transition-transform hover:scale-105"
    >
      View Projects
    </a>
  </div>
</section>
      <Internship />
      <AffiliationsSection />
    </div>
  );
};

export default Home;
