import React from 'react';
import BackgroundImage from '../assets/privacy.jpg'; // Ensure this image path is correct
import { FaLock, FaUserShield, FaShieldAlt, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';

function PrivacyPolicy() {
  return (
    <div className="bg-gray-100 min-h-screen py-10">
      {/* Header with Background Image */}
      <div
        className="relative h-64 bg-cover bg-center"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight text-center">Privacy Policy</h1>
        </div>
      </div>

      {/* Content */}
      <motion.div
        className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-[-4rem] relative z-10"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <p className="mb-6 text-gray-700">
          Thank you for visiting the website of <span className="text-blue-500">VMAD Technologies</span>. Your privacy is important to us, and we are committed to protecting your personal information.
        </p>

        <p className="mb-6 text-gray-700">
          This Privacy Policy explains how we collect, use, share, and protect your information when you use our website and services. By accessing or using VMAD Technologies, you consent to the practices described in this policy.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-900 flex items-center">
          <FaShieldAlt className="text-blue-500 mr-2" />
          Information We Collect
        </h2>
        <p className="mb-6 text-gray-700">
          We collect various types of information to provide and improve our services, including:
        </p>
        <ul className="list-disc list-inside mb-6 text-gray-700">
          <li>
            <strong>Personal Information:</strong> We may collect your name, email address, phone number, and other contact information when you register for our services or communicate with us.
          </li>
          <li>
            <strong>Account Information:</strong> When you create an account, we collect data such as your username and password for authentication purposes.
          </li>
          <li>
            <strong>Payment Information:</strong> To process payments, we collect your financial information, including credit card numbers and billing information, through secure payment gateways.
          </li>
          <li>
            <strong>Usage Data:</strong> We collect data about your interactions with our website, such as your IP address, browser type, and the pages you visit to help us improve our services.
          </li>
          <li>
            <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience, analyze usage patterns, and deliver personalized content. You can manage your cookie preferences through your browser settings.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-blue-900 flex items-center">
          <FaLock className="text-blue-500 mr-2" />
          How We Use Your Information
        </h2>
        <p className="mb-6 text-gray-700">
          The information we collect is used for various purposes, including:
        </p>
        <ul className="list-disc list-inside mb-6 text-gray-700">
          <li>
            <strong>Service Delivery:</strong> We use your information to provide, maintain, and improve our services, including processing transactions and providing customer support.
          </li>
          <li>
            <strong>Communication:</strong> Your contact information allows us to communicate with you regarding your account, respond to inquiries, and send updates or promotional content.
          </li>
          <li>
            <strong>Security:</strong> We use your information to monitor for fraudulent activity, protect against unauthorized access, and ensure the security of our platform.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-blue-900 flex items-center">
          <FaUserShield className="text-blue-500 mr-2" />
          Sharing Your Information
        </h2>
        <p className="mb-6 text-gray-700">
          We respect your privacy and do not sell or rent your personal information. We may share your information with trusted third-party service providers who assist us in operating our website or providing services on our behalf. Additionally, we may disclose your information if required by law or in response to valid requests from public authorities.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-900 flex items-center">
          <FaLock className="text-blue-500 mr-2" />
          Security of Your Information
        </h2>
        <p className="mb-6 text-gray-700">
          We implement a variety of security measures to protect your personal information from unauthorized access and misuse. While we strive to protect your information, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-900 flex items-center">
          <FaEnvelope className="text-blue-500 mr-2" />
          Contact Us
        </h2>
        <p className="mb-6 text-gray-700">
          If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at: <span className='text-blue-500'>info@vmadtechnologies.com</span>
        </p>
      </motion.div>
    </div>
  );
}

export default PrivacyPolicy;
