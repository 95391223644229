import { motion } from 'framer-motion';
import { FaHandshake, FaLock, FaRocket, FaClipboardCheck, FaThumbsUp, FaAward } from 'react-icons/fa';

const chooseUs = [
  {
    title: "Reliability & Trust",
    description: "As an MSME and ISO-certified company, we ensure top-notch service with transparency and dependability in every project.",
    icon: <FaHandshake className="text-5xl text-purple-600 mb-4" aria-label="Reliability and Trust Icon" />,
  },
  {
    title: "Cost Effectiveness",
    description: "We provide quality solutions that fit your budget, delivering high value without compromising on performance.",
    icon: <FaThumbsUp className="text-5xl text-purple-600 mb-4" aria-label="Cost Effectiveness Icon" />,
  },
  {
    title: "Data Security",
    description: "Your data’s security is our priority. We implement strict security protocols to safeguard your information.",
    icon: <FaLock className="text-5xl text-purple-600 mb-4" aria-label="Data Security Icon" />,
  },
  {
    title: "Quick Turnaround",
    description: "We meet deadlines without sacrificing quality, ensuring fast and efficient project delivery.",
    icon: <FaRocket className="text-5xl text-purple-600 mb-4" aria-label="Quick Turnaround Icon" />,
  },
  {
    title: "Certified Expertise",
    description: "Our certified professionals bring industry-leading expertise to every project for top-tier results.",
    icon: <FaAward className="text-5xl text-purple-600 mb-4" aria-label="Certified Expertise Icon" />,
  },
  {
    title: "Customer Satisfaction",
    description: "Client satisfaction is our top priority, ensuring your needs are met and expectations exceeded.",
    icon: <FaClipboardCheck className="text-5xl text-purple-600 mb-4" aria-label="Customer Satisfaction Icon" />,
  },
];

const containerVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const WhyChooseUs = () => {
  return (
    <div className="max-w-7xl bg-gradient-to-b from-blue-50 to-purple-50 mx-auto px-4 py-12">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="text-center"
      >
        <motion.h2
          className="text-5xl font-bold text-purple-600 mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Why Choose Us?
        </motion.h2>
        <motion.p
          className="text-xl text-gray-600 mb-10"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          We deliver impactful IT solutions backed by trust and expertise.
        </motion.p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {chooseUs.map((item, index) => (
            <motion.div
              key={index}
              variants={cardVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 relative overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-purple-400 to-purple-600 opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-xl"></div>
              <div className="relative z-10 flex flex-col items-center">
                {item.icon}
                <h3 className="text-2xl font-semibold text-center mb-4 text-gray-900">
                  {item.title}
                </h3>
                <p className="text-gray-700 text-sm text-center">{item.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default WhyChooseUs;
