import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import Navbar from './components/navbar'; // Import Navbar
import Home from './Home';
import ServicesPage from './pages/services';
import Footer from './components/footer';
import AboutUs from './pages/aboutus';
import PrivacyPolicy from './pages/privacy';
import ContactUs from './pages/contactus';
import CareerPage from './pages/career';
import Error from './pages/error';
const App = () => {
    return (
        <Router>
            {/* SEO Head with meta tags */}
            <Helmet>
                <title>VMAD Technologies - Innovative IT Solutions</title>
                <meta 
                    name="description" 
                    content="VMAD Technologies provides innovative IT solutions including app development, web development, digital marketing, and more." 
                />
                <link rel="canonical" href="https://vmadtechnologies.in" />
            </Helmet>

            {/* Navbar will be rendered on every page */}
            <Navbar />

            <Routes>
                {/* Define your routes here */}
                <Route path="/" element={<Home />} />
                <Route path="/our-services" element={<ServicesPage />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/careers" element={<CareerPage />} />
                {/* Add other routes as needed */}
                <Route path="*" element={<Error />} /> {/* Catch-all route for 404 */}
            </Routes>

            <Footer />
        </Router>
    );
};

export default App;
