import { FaCode, FaMobileAlt, FaBrain, FaJava, FaPython, FaUsers, FaBullhorn, FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from 'react';

// Internship data
const internships = [
  { title: "Web Development", icon: <FaCode />, description: "Master modern web technologies & frameworks." },
  { title: "App Development", icon: <FaMobileAlt />, description: "Build engaging mobile apps for iOS & Android." },
  { title: "Machine Learning", icon: <FaBrain />, description: "Dive into AI & data-driven insights." },
  { title: "Java Development", icon: <FaJava />, description: "Enhance your Java programming skills." },
  { title: "Python Development", icon: <FaPython />, description: "Work on innovative Python projects." },
  { title: "HR Management", icon: <FaUsers />, description: "Learn key HR strategies and best practices." },
  { title: "Marketing", icon: <FaBullhorn />, description: "Create impactful marketing campaigns." },
];

// Slider settings with autoplay enabled
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 600,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// FAQ Data including general questions
const FAQs = [
  { question: "What is the duration of the internships?", answer: "The internships typically last for 1 to 3 months, depending on the specific program.", icon: <FaInfoCircle /> },
  { question: "Are these internships paid?", answer: "Yes, we offer paid internship opportunities based on performance and the type of internship.", icon: <FaInfoCircle /> },
  { question: "Do I need to have prior experience?", answer: "No prior experience is required, but a basic understanding of the field is beneficial.", icon: <FaInfoCircle /> },
  { question: "Will I receive a certificate after completion?", answer: "Yes, all participants will receive a certification of completion at the end of their internship.", icon: <FaInfoCircle /> },
  { question: "How do I apply for an internship?", answer: "You can apply by clicking on the 'Apply Now' button and filling out the application form.", icon: <FaInfoCircle /> },
  { question: "How to contact us?", answer: "You can reach out via our contact form or email us at vmadtechnologies@gmail.com.", icon: <FaEnvelope /> },
  { question: "Who are we?", answer: "We are a tech-driven company providing innovative solutions and internships in various domains.", icon: <FaInfoCircle /> },
];

const InternshipSection = () => {
  const [openIndex, setOpenIndex] = useState(null); // State to manage FAQ toggle

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle open/close for FAQs
  };

  return (
    <section className="bg-gradient-to-b from-blue-50 to-purple-50 py-16 px-8">
      <div className="max-w-7xl mx-auto">

        {/* Section Header */}
        <motion.header
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          className="text-center mb-12"
        >
          <h2 className="text-5xl font-bold text-purple-700 mb-4">Join Us Now</h2>
          <p className="text-xl leading-relaxed text-gray-600">Explore hands-on internships designed to propel your career with real-world experience and mentorship.</p>
        </motion.header>

        {/* Internship Slider */}
        <Slider {...sliderSettings} className="mb-12">
          {internships.map((item, index) => (
            <div key={index} className="p-6">
              <motion.article
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                className="flex flex-col items-center bg-gradient-to-r from-purple-100 to-blue-100 p-8 rounded-lg shadow-lg hover:shadow-xl transition-all"
                role="img" 
                aria-label={`Internship in ${item.title}`}
              >
                <div className="text-6xl text-purple-600 mb-4">{item.icon}</div>
                <h3 className="text-3xl font-semibold mb-2 text-gray-800">{item.title}</h3>
                <p className="text-gray-600 text-lg text-center">{item.description}</p>
              </motion.article>
            </div>
          ))}
        </Slider>

        {/* Perks Section */}
        <div className="bg-gradient-to-r from-purple-200 to-blue-200 py-12 px-8 rounded-lg text-center mt-12">
          <motion.h4
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-4xl font-semibold text-purple-700 mb-6"
          >
            Benefits & Perks of Our Internships
          </motion.h4>
          <div className="grid text-lg grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 text-gray-700">
            <motion.p whileHover={{ scale: 1.05 }}>✔️ Real-World Project Experience</motion.p>
            <motion.p whileHover={{ scale: 1.05 }}>✔️ Certification of Completion</motion.p>
            <motion.p whileHover={{ scale: 1.05 }}>✔️ Paid Internship Opportunities</motion.p>
            <motion.p whileHover={{ scale: 1.05 }}>✔️ Potential for Full-Time Hiring</motion.p>
            <motion.p whileHover={{ scale: 1.05 }}>✔️ Flexible Working Environment</motion.p>
            <motion.p whileHover={{ scale: 1.05 }}>✔️ Mentorship from Industry Experts</motion.p>
          </div>
        </div>

        {/* Apply Now CTA */}
        <div className="text-center mt-12">
          <motion.a
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            href="/careers"
            className="bg-purple-700 text-white px-8 py-4 rounded-full text-lg font-semibold shadow-lg hover:bg-purple-600 transition-all"
          >
            Apply Now for Internships
          </motion.a>
        </div>

        {/* FAQ Section */}
        <div className="mt-16">
          <h3 className="text-3xl font-bold text-purple-700 text-center mb-6">Frequently Asked Questions about Internships</h3>
          <div className="max-w-3xl mx-auto">
            {FAQs.map((faq, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg mb-4">
                <button
                  className="w-full text-left p-4 focus:outline-none rounded-lg bg-gradient-to-r from-purple-50 to-blue-50 hover:bg-purple-100 transition-colors flex items-center justify-between"
                  onClick={() => toggleFAQ(index)}
                  aria-expanded={openIndex === index} // Accessibility improvement
                  aria-controls={`faq-answer-${index}`} // Link to the answer
                >
                  <div className="flex items-center">
                    <span className="text-purple-600 text-2xl mr-2">{faq.icon}</span>
                    <h4 className="text-lg font-semibold text-gray-800">{faq.question}</h4>
                  </div>
                  <span className={`text-gray-600 text-2xl ${openIndex === index ? 'rotate-180' : ''}`}>▼</span>
                </button>
                {openIndex === index && (
                  <div id={`faq-answer-${index}`} className="p-4 text-gray-700 border-t">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InternshipSection;
