import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCode, FaMobileAlt, FaBrain, FaUsers, FaFileAlt, FaVideo, FaBullhorn } from 'react-icons/fa';
import app from '../assets/app.png';
import web from '../assets/web.png';
import ui from '../assets/UI.png';
import video from '../assets/Video.png';
import social from '../assets/Social.png';
import ml from '../assets/ML.png';
import transcript from '../assets/transcriptor.png';
import digital from '../assets/Digital.png';
import { Helmet } from 'react-helmet'; // Import react-helmet

const services = [
  {
    name: "Website Development",
    description: "We design and develop custom web solutions that are tailored to meet the unique needs of your business. Using the latest technologies, we ensure your website is fast, responsive, and user-friendly, enhancing your online presence and user experience.",
    icon: <FaCode className="text-3xl text-purple-600 mb-2" />,
    image: web,
    link: '/contact-us',
    alt: 'Website Development Service',
  },
  {
    name: "App Development",
    description: "Our team creates engaging and efficient mobile applications for both iOS and Android platforms. We focus on delivering a seamless user experience, ensuring that your app is intuitive, functional, and well-integrated with the latest technologies.",
    icon: <FaMobileAlt className="text-3xl text-purple-600 mb-2" />,
    image: app,
    link: '/contact-us',
    alt: 'App Development Service',
  },
  {
    name: "ML Development",
    description: "We offer innovative machine learning solutions that help enhance business operations. Our expertise in data analysis allows us to harness the power of data, enabling better decision-making and more efficient processes.",
    icon: <FaBrain className="text-3xl text-purple-600 mb-2" />,
    image: ml,
    link: '/contact-us',
    alt: 'Machine Learning Development Service',
  },
  {
    name: "Social Media Management",
    description: "Boost your online presence with our expert social media services. We create and manage effective campaigns that not only engage your audience but also drive traffic and increase brand awareness across platforms.",
    icon: <FaUsers className="text-3xl text-purple-600 mb-2" />,
    image: social,
    link: '/contact-us',
    alt: 'Social Media Management Service',
  },
  {
    name: "UI/UX Design",
    description: "Our UI/UX design services focus on creating intuitive and engaging user experiences. We conduct thorough user research and testing to ensure that the final product not only meets but exceeds user expectations.",
    icon: <FaFileAlt className="text-3xl text-purple-600 mb-2" />,
    image: ui,
    link: '/contact-us',
    alt: 'UI/UX Design Service',
  },
  {
    name: "Video Editing",
    description: "We provide professional video editing services aimed at creating impactful storytelling. Our team transforms your raw footage into captivating visual narratives that resonate with your audience.",
    icon: <FaVideo className="text-3xl text-purple-600 mb-2" />,
    image: video,
    link: '/contact-us',
    alt: 'Video Editing Service',
  },
  {
    name: "Digital Marketing",
    description: "Our comprehensive digital marketing strategies are designed to grow your brand online. We cover all aspects, from SEO to PPC, ensuring your marketing efforts yield maximum results and visibility.",
    icon: <FaBullhorn className="text-3xl text-purple-600 mb-2" />,
    image: digital,
    link: '/contact-us',
    alt: 'Digital Marketing Service',
  },
  {
    name: "Transcription",
    description: "We offer accurate and timely transcription services for various formats. Our team ensures that every detail is captured with precision, providing you with high-quality transcripts to meet your needs.",
    icon: <FaFileAlt className="text-3xl text-purple-600 mb-2" />,
    image: transcript,
    link: '/contact-us',
    alt: 'Transcription Service',
  },
];

const TabbedServices = () => {
  const [activeTab, setActiveTab] = useState(0);

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <div className="max-w-7xl bg-gradient-to-b from-blue-50 to-purple-50 mx-auto px-4 py-8">
      <Helmet>
        <title>VMAD Technologies - Our Services</title>
        <meta name="description" content="Explore our wide range of services including Website Development, App Development, Machine Learning, and more." />
      </Helmet>
      <h2 className="text-5xl font-bold text-center mb-2 text-purple-600">What We Do?</h2>
      <p className="text-center text-xl text-gray-600 mb-6">Transforming ideas into reality with innovative solutions.</p>
      <div className="flex flex-wrap justify-center mb-6 space-x-2">
        {services.map((service, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`flex items-center scale-90 px-5 py-3 mx-1 mb-1 rounded-lg text-lg transition-all duration-300 border-2 ${
              activeTab === index ? 'border-purple-600 text-black bg-white transform scale-100 shadow-lg' : 'border-gray-300 scale-90 text-black bg-gray-100 hover:bg-purple-500 hover:text-white'
            }`}
          >
            {service.icon}
            <span className="ml-2">{service.name}</span>
          </button>
        ))}
      </div>
      <motion.div
        className="bg-purple-100 text-black p-8 rounded-lg shadow-lg flex flex-col md:flex-row items-center md:h-80"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.div key={activeTab} variants={itemVariants} className="flex-1 text-center md:text-left h-full">
          <div className="text-4xl mb-4">{services[activeTab].icon}</div>
          <h3 className="text-4xl font-bold mb-2">{services[activeTab].name}</h3>
          <p className="text-gray-950 mb-4 text-sm md:text-lg">{services[activeTab].description}</p>
          <div className="flex justify-start">
            <a href={services[activeTab].link} className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-500 transition-colors duration-300">
              <span className="mr-1">💼</span> Hire Us
            </a>
          </div>
        </motion.div>
        <motion.div key={`${activeTab}-image`} variants={itemVariants} className="flex-none h-full">
          <img
            src={services[activeTab].image}
            alt={services[activeTab].alt}
            className="h-full w-auto object-contain rounded-lg"
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default TabbedServices;
