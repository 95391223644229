import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing the head
import MissionImage from '../assets/mission.png'; // Update the path to your actual image
import VisionImage from '../assets/vision.png'; // Update the path to your actual image
import ValuesImage from '../assets/values.png'; // Update the path to your actual image
import ContactImage from '../assets/contact.png'; // Update the path to your actual image
import TeamImage from '../assets/team.png'; // Update the path to your actual image

function AboutUsComponent() {
    const sections = useRef([]);

    useEffect(() => {
        const revealSections = () => {
            sections.current.forEach((section, index) => {
                if (section) {
                    setTimeout(() => {
                        section.classList.add('opacity-100');
                    }, index * 300);
                }
            });
        };

        revealSections();
    }, []);

    return (
        <div className="flex flex-col min-h-screen">
            <Helmet>
                <title>About Us - VMAD Technologies</title>
                <meta name="description" content="Learn about VMAD Technologies, our mission, vision, values, and team driving innovation in technology solutions." />
                <meta name="keywords" content="VMAD Technologies, About Us, Mission, Vision, Values, Team" />
            </Helmet>
            <main className="flex-1">
                {/* Mission Section */}
                <section className="w-full py-12 bg-gradient-to-r from-purple-100 via-blue-50 to-white md:py-24 lg:py-32">
                    <div className="container px-4 md:px-8">
                        <div className="grid gap-12 md:gap-16 md:grid-cols-2">
                            <div className="space-y-6 opacity-0 transition-opacity duration-700" ref={el => sections.current[0] = el}>
                                <div className="inline-block rounded-full bg-purple-500 px-4 py-1 text-xs font-semibold text-white uppercase tracking-wide">
                                    Mission
                                </div>
                                <h2 className="text-3xl font-extrabold sm:text-4xl md:text-5xl xl:text-6xl text-gray-900" tabIndex="0">
                                    Empowering Businesses Through Innovative Solutions
                                </h2>
                                <p className="text-lg text-gray-700 md:text-xl">
                                    At VMAD Technologies, our mission is to provide cutting-edge software solutions that empower businesses to thrive in the digital landscape. We aim to bridge the gap between technology and business needs, offering tailored solutions that drive efficiency and growth.
                                </p>
                            </div>
                            <img
                                src={MissionImage}
                                width="500"
                                height="500"
                                alt="Mission: Empowering Businesses Through Innovative Solutions"
                                className="mx-auto max-w-full rounded-lg shadow-lg"
                            />
                        </div>
                    </div>
                </section>

                {/* Vision Section */}
                <section className="w-full py-12 bg-gradient-to-r from-white via-blue-50 to-purple-100 md:py-24 lg:py-32">
                    <div className="container px-4 md:px-8">
                        <div className="grid gap-12 md:gap-16 md:grid-cols-2">
                            <img
                                src={VisionImage}
                                width="550"
                                height="310"
                                alt="Vision: Envisioning a Future of Seamless Technology Integration"
                                className="mx-auto rounded-xl shadow-lg"
                            />
                            <div className="space-y-6 opacity-0 transition-opacity duration-700" ref={el => sections.current[1] = el}>
                                <div className="inline-block rounded-full bg-purple-600 px-4 py-1 text-xs font-semibold text-white uppercase tracking-wide">
                                    Vision
                                </div>
                                <h2 className="text-3xl font-extrabold sm:text-4xl md:text-5xl xl:text-6xl text-gray-900" tabIndex="0">
                                    Envisioning a Future of Seamless Technology Integration
                                </h2>
                                <p className="text-lg text-gray-700 md:text-xl">
                                    Our vision is to create a world where technology is seamlessly integrated into every aspect of business operations, fostering innovation and efficiency. We aspire to be a leader in providing technology solutions that empower organizations to adapt and thrive in an ever-changing environment.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Values Section */}
                <section className="w-full py-12 bg-gradient-to-r from-purple-100 via-blue-50 to-white md:py-24 lg:py-32">
                    <div className="container px-4 md:px-8">
                        <div className="grid gap-12 md:gap-16 md:grid-cols-2">
                            <div className="space-y-6 opacity-0 transition-opacity duration-700" ref={el => sections.current[2] = el}>
                                <div className="inline-block rounded-full bg-purple-500 px-4 py-1 text-xs font-semibold text-white uppercase tracking-wide">
                                    Values
                                </div>
                                <h2 className="text-3xl font-extrabold sm:text-4xl md:text-5xl xl:text-6xl text-gray-900" tabIndex="0">
                                    Core Values That Define Us
                                </h2>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                    <div className="p-6 bg-gray-200 border border-gray-200 rounded-lg shadow-lg">
                                        <h3 className="text-xl font-semibold text-gray-900">Innovation</h3>
                                        <p className="text-gray-700">
                                            We are committed to fostering a culture of innovation, continuously exploring new technologies and methodologies to provide our clients with the best solutions.
                                        </p>
                                    </div>
                                    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-lg">
                                        <h3 className="text-xl font-semibold text-gray-900">Integrity</h3>
                                        <p className="text-gray-700">
                                            Integrity is at the heart of our operations. We strive to build trust through transparency, accountability, and ethical practices in all our dealings.
                                        </p>
                                    </div>
                                    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-lg">
                                        <h3 className="text-xl font-semibold text-gray-900">Collaboration</h3>
                                        <p className="text-gray-700">
                                            We believe in the power of collaboration, working closely with our clients and partners to achieve shared goals and drive success.
                                        </p>
                                    </div>
                                    <div className="p-6 bg-gray-200 border border-gray-200 rounded-lg shadow-lg">
                                        <h3 className="text-xl font-semibold text-gray-900">Customer Focus</h3>
                                        <p className="text-gray-700">
                                            Our clients are our priority. We listen to their needs and tailor our services to ensure their satisfaction and success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <img
                                src={ValuesImage}
                                width="550"
                                height="310"
                                alt="Values: Core Values That Define Us"
                                className="mx-auto rounded-xl shadow-lg"
                            />
                        </div>
                    </div>
                </section>

                {/* Contact Us Section */}
                <section className="w-full py-12 bg-gradient-to-r from-white via-blue-50 to-purple-100 md:py-24 lg:py-32">
                    <div className="container px-4 md:px-8">
                        <div className="grid gap-12 md:gap-16 md:grid-cols-2">
                            <img
                                src={ContactImage}
                                width="550"
                                height="310"
                                alt="Contact Us: Get in Touch"
                                className="mx-auto rounded-xl shadow-lg"
                            />
                            <div className="space-y-6 opacity-0 transition-opacity duration-700" ref={el => sections.current[4] = el}>
                                <div className="inline-block rounded-full bg-purple-600 px-4 py-1 text-xs font-semibold text-white uppercase tracking-wide">
                                    Contact Us
                                </div>
                                <h2 className="text-3xl font-extrabold sm:text-4xl md:text-5xl xl:text-6xl text-gray-900" tabIndex="0">
                                    Get in Touch
                                </h2>
                                <p className="text-lg text-gray-700 md:text-xl">
                                    We would love to hear from you! For inquiries about our services or to learn more about our solutions, please reach out to us.
                                </p>
                                <a href="/contact-us" className="inline-block bg-purple-500 text-white rounded-lg py-3 px-6 text-lg font-semibold">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Team Section */}
                <section className="w-full py-12 bg-gradient-to-r from-purple-100 via-blue-50 to-white md:py-24 lg:py-32">
                    <div className="container px-4 md:px-8">
                        <div className="grid gap-12 md:gap-16 md:grid-cols-2">
                            <div className="space-y-6 opacity-0 transition-opacity duration-700" ref={el => sections.current[3] = el}>
                                <div className="inline-block rounded-full bg-purple-500 px-4 py-1 text-xs font-semibold text-white uppercase tracking-wide">
                                    Meet Our Team
                                </div>
                                <h2 className="text-3xl font-extrabold sm:text-4xl md:text-5xl xl:text-6xl text-gray-900" tabIndex="0">
                                    Dedicated Professionals Committed to Your Success
                                </h2>
                                <p className="text-lg text-gray-700 md:text-xl">
                                    Our team of experienced professionals is dedicated to delivering the best technology solutions. We are passionate about what we do and strive for excellence in every project we undertake.
                                </p>
                            </div>
                            <img
                                src={TeamImage}
                                width="550"
                                height="310"
                                alt="Team: Dedicated Professionals Committed to Your Success"
                                className="mx-auto rounded-xl shadow-lg"
                            />
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default AboutUsComponent;
