import React from 'react';
import { Link } from 'react-router-dom'; // Make sure you have react-router-dom installed

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-6xl font-bold text-purple-800">404</h1>
      <h2 className="text-2xl md:text-3xl font-semibold text-gray-700 mt-4">Oops! Page Not Found</h2>
      <p className="text-gray-600 mt-2 text-center px-4">
        It seems we can't find the page you're looking for. 
        You might want to check the URL or return to the homepage.
      </p>
      <Link to="/" className="mt-6 px-4 py-2 bg-purple-800 text-white rounded hover:bg-purple-700 transition duration-200">
        Go to Homepage
      </Link>
    </div>
  );
};

export default NotFound;
