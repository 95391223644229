import React from 'react';
import { FaArrowRight } from 'react-icons/fa'; // Import arrow icon
import Hero from '../assets/hero.png';

const HeroSection = () => {
    return (
        <section className="hero bg-gradient-to-b md:bg-gradient-to-r from-purple-200 via-blue-100 to-white w-full min-h-[100vh] flex flex-col lg:flex-row items-center justify-between p-8 py-20 lg:p-10">
            {/* Text Area */}
            <div className="text-area w-full lg:w-1/2 space-y-6 text-center lg:text-left">
                {/* Main heading with keywords */}
                <h1 className="text-5xl lg:text-7xl font-extrabold text-gray-800 font-serif transition-transform transform">
                    Welcome to <span className="text-purple-700">VMAD Technologies</span>
                </h1>
                {/* Subheading with more targeted keywords */}
                <p className="text-lg lg:text-2xl text-gray-700 mb-6 font-serif">
                    We provide comprehensive IT solutions tailored to your needs, from web development to machine learning.
                    Join our project-based internships to gain hands-on experience and shape your future in technology!
                </p>

                {/* Internal link with proper anchor text */}
                <a href="/our-services" className="inline-flex items-center gap-3 bg-purple-700 text-white py-3 px-6 rounded-full shadow-lg hover:bg-purple-700 transition duration-300 transform hover:scale-105">
                    Explore Our IT Services <FaArrowRight className="ml-2" />
                </a>
            </div>

            {/* Image Area with optimized alt text */}
            <div className="image-area w-full lg:w-1/2 flex justify-center mt-10 lg:mt-0">
                <img src={Hero} alt="VMAD Technologies IT Solutions - Empowering Business with Technology" className="w-full h-auto max-w-lg transition-transform transform hover:scale-110 hover:rotate-1" />
            </div>
        </section>
    );
};

export default HeroSection;
