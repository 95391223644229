import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { FaHome, FaInfoCircle, FaServicestack, FaPhoneAlt, FaShieldAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-purple-50 border-gray-300 border-t-2 text-gray-800 py-8" aria-label="Footer">
      <div className="max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div className="flex flex-col">
            <h4 className="text-xl font-bold mb-2">About Us</h4>
            <p className="text-lg leading-relaxed">
              VMAD Technologies is dedicated to delivering exceptional IT solutions and fostering project-based learning to empower the next generation of innovators.
            </p>
          </div>
          <div className="flex flex-col">
            <h4 className="text-xl font-bold mb-2">Quick Links</h4>
            <ul className="text-lg space-y-1">
              <li className="flex items-center">
                <FaHome className="mr-2" />
                <a href="/about-us" className="hover:text-purple-700 transition-colors" aria-label="About Us">About Us</a>
              </li>
              <li className="flex items-center">
                <FaServicestack className="mr-2" />
                <a href="/our-services" className="hover:text-purple-700 transition-colors" aria-label="Our Services">Our Services</a>
              </li>
              <li className="flex items-center">
                <FaInfoCircle className="mr-2" />
                <a href="/careers" className="hover:text-purple-700 transition-colors" aria-label="Careers">Careers</a>
              </li>
              <li className="flex items-center">
                <FaPhoneAlt className="mr-2" />
                <a href="/contact-us" className="hover:text-purple-700 transition-colors" aria-label="Contact Us">Contact Us</a>
              </li>
              <li className="flex items-center">
                <FaShieldAlt className="mr-2" />
                <a href="/privacy" className="hover:text-purple-700 transition-colors" aria-label="Privacy Policy">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col">
            <h4 className="text-lg font-bold mb-2">Follow Us</h4>
            <div className="flex space-x-4 text-xl">
              <a href="https://x.com/Vmad_techn" aria-label="Twitter" className="hover:text-purple-700 transition-colors">
                <FaXTwitter />
              </a>
              <a href="https://www.linkedin.com/company/vmadtechnologies" aria-label="LinkedIn" className="hover:text-purple-700 transition-colors">
                <FaLinkedinIn />
              </a>
              <a href="https://www.instagram.com/vmad_technologies/" aria-label="Instagram" className="hover:text-purple-700 transition-colors">
                <FaInstagram />
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <h4 className="text-lg font-bold mb-2">Contact Us</h4>
            <div className="flex items-center mb-2">
              <FaMapMarkerAlt className="mr-2 text-xl" />
              <p className="text-lg">Noida, Uttar Pradesh, India</p>
            </div>
            <div className="flex items-center mb-2">
              <FaEnvelope className="mr-2 text-xl" />
              <p className="text-lg">vmadtechnologies@gmail.com</p>
            </div>
            <div className="flex items-center">
              <FaPhoneAlt className="mr-2 text-xl" />
              <p className="text-lg">+91 8318033578</p>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-600 pt-4 text-center text-sm">
          <p>&copy; {new Date().getFullYear()} VMAD Technologies. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
