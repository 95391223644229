import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { motion } from 'framer-motion';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix marker icon issues with Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Define your map center coordinates for Noida, India
const position = [28.4744, 77.5040]; // Latitude and longitude for Noida, India

function ContactUs() {
  return (
    <main className="min-h-screen py-20 bg-gradient-to-r from-purple-100 to-blue-100">
      {/* Header Section */}
      <header className="text-center text-gray-800 mb-12">
        <h1 className="text-5xl font-bold">Let's Make Your Ideas a Reality</h1>
        <p className="mt-4 text-lg italic">We’re here to assist you with any inquiries or projects!</p>
      </header>

      {/* Content */}
      <motion.div
        className="max-w-5xl mx-auto p-8 bg-white shadow-xl rounded-lg"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Tagline Section */}
        <section className="text-center mb-10">
          <h2 className="text-3xl font-semibold text-purple-600">Have an Idea? Hire Us!</h2>
          <p className="mt-2 text-gray-600">
            Our team at VMAD Technologies is excited to help bring your vision to life.
          </p>
        </section>

        {/* Contact Form Section */}
        <section className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
          <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-purple-600">Get in Touch</h3>
            <form action="https://formspree.io/f/xyzygyny" method="POST"> {/* Replace YOUR_FORM_ID */}
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Your Message"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-purple-600 text-white font-semibold py-2 rounded-lg hover:bg-purple-700 transition duration-200"
              >
                Send Message
              </button>
            </form>
          </div>

          {/* Contact Information Section */}
          <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-purple-600">Contact Information</h3>
            <div className="mb-4 flex items-center">
              <FaPhoneAlt className="text-purple-600 mr-2" />
              <span className="text-gray-700">+91 8318033578</span>
            </div>
            <div className="mb-4 flex items-center">
              <FaEnvelope className="text-purple-600 mr-2" />
              <span className="text-gray-700">vmadtechnologies@gmail.com</span>
            </div>
            <div className="mb-4 flex items-center">
              <FaMapMarkerAlt className="text-purple-600 mr-2" />
              <span className="text-gray-700">Noida, Uttar Pradesh, India</span>
            </div>
            <h4 className="text-lg font-semibold mt-4 text-purple-600">Follow Us</h4>
            <div className="flex space-x-4 mt-2">
              <a href="https://www.instagram.com/vmad_technologies/" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="text-purple-600 hover:text-purple-700">
                <FaInstagram />
              </a>
              <a href="https://x.com/Vmad_techn" target="_blank" rel="noopener noreferrer" aria-label="Twitter" className="text-purple-600 hover:text-purple-700">
                <FaXTwitter />
              </a>
              <a href="https://www.linkedin.com/company/vmadtechnologies" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" className="text-purple-600 hover:text-purple-700">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </section>

        {/* Hire Us Section */}
        <section className="text-center mb-10">
          <h2 className="text-3xl font-semibold text-purple-600">Hire Us!</h2>
          <p className="mt-2 text-gray-600">
            Our dedicated team is ready to help you with your next project. Let's discuss your ideas and make them happen!
          </p>
        </section>

        {/* Leaflet Map Section */}
        <section className="mt-6">
          <h2 className="text-3xl font-semibold mb-4 text-purple-600">Our Location</h2>
          <p className="mb-2 text-gray-600">
            Visit us at our office to discuss your project in person!
          </p>
          <MapContainer center={position} zoom={15} style={{ height: '300px', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
              <Popup>Your location here!</Popup>
            </Marker>
          </MapContainer>
        </section>
      </motion.div>
    </main>
  );
}

export default ContactUs;
